@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.c-topSlider {
  overflow: hidden;
  width: 100%;
  height: 714px; }
  @media screen and (max-width: 768px) {
    .c-topSlider {
      height: 512px; } }
  .c-topSlider__list {
    width: 100%;
    height: 100%;
    position: relative; }
  .c-topSlider__item {
    width: calc(100% - -62px) !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(-10deg);
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      .c-topSlider__item {
        width: calc(100% - -45px) !important; } }
  .c-topSlider__img {
    width: 100%;
    height: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: skew(10deg) scale(1.3); }
    @media screen and (max-width: 568px) {
      .c-topSlider__img {
        transform: skew(10deg) scale(1.8); } }

#top .c-topSlider {
  position: relative; }
  #top .c-topSlider:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 712px 114px 0 0;
    border-color: #0F2884 transparent transparent transparent;
    opacity: .7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20; }
    @media screen and (max-width: 768px) {
      #top .c-topSlider:before {
        border-width: 392px 63px 0 0; } }

#top #wrapper {
  padding: 0 0; }
  @media screen and (max-width: 1164px) {
    #top #wrapper {
      padding: 60px 0 0; } }

#top #newslist {
  padding-bottom: 0; }
  @media screen and (max-width: 768px) {
    #top #newslist .c-newslist__wrap {
      background-color: #F7F7F7;
      padding: 15px 0 30px; } }
  @media screen and (max-width: 768px) {
    #top #newslist .c-newslist dl dt {
      margin-bottom: 5px; } }
  #top #newslist .c-newslist dl dd {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

#top .p-reason {
  padding: 20px 0; }
  @media screen and (max-width: 1164px) {
    #top .p-reason {
      padding-top: 80px;
      padding-bottom: 20px;
      margin-bottom: 60px; } }

#top .p-service {
  overflow: hidden; }
  #top .p-service .c-serviceList {
    margin-top: 40px; }
  #top .p-service .l-col2__block {
    align-items: flex-start; }
  #top .p-service .l-col2__detail {
    padding-top: 95px; }
    @media screen and (max-width: 768px) {
      #top .p-service .l-col2__detail {
        padding-top: 0px; } }

#top .p-factory {
  position: relative;
  overflow: hidden;
  margin-bottom: 120px; }
  @media screen and (max-width: 768px) {
    #top .p-factory {
      padding-bottom: 20px;
      margin-bottom: 0; } }
  #top .p-factory:before {
    content: '';
    background: url("/inc/image/common/bg/bg_dot-gray.jpg");
    display: block;
    width: 500px;
    height: 100%;
    position: absolute;
    top: 50%;
    left: -40px;
    transform: translateY(-50%) skew(-10deg);
    z-index: 1; }
    @media screen and (max-width: 768px) {
      #top .p-factory:before {
        width: 100%;
        top: 0;
        left: 0;
        transform: none; } }
  @media screen and (max-width: 1164px) {
    #top .p-factory .l-section {
      padding-top: 80px; } }
